import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://adataapi.stoykotolev.com/graphql'
});

const headerLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      cors: '*'
    }
  };
});

export const client = new ApolloClient({
  link: headerLink.concat(httpLink),
  cache: new InMemoryCache()
});
