import React, { useState } from 'react';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import {
  FormAutoComplete,
  FormInput,
  FormNumericTextBox,
  FormRadioGroup,
  FormTextArea
} from '../../utils/formComponents';
import { Button } from '@progress/kendo-react-buttons';
import { requiredValidator } from '../../utils/validators';
import { Loader } from '@progress/kendo-react-indicators';
import CattleBreeds from '../../utils/cattlesBreeds';
import {
  ageClassEU,
  marblingRating,
  ratingLetters,
  ratingNumber,
  ratingSymbol
} from '../../utils/data';
import { AnimalGradeType } from '../../types';
import { useMutation } from '@apollo/client';
import { SAVE_ANIMAL } from '../../utils/mutations';
import { GET_ANIMALS_DATA } from '../../utils/queries';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AnimalGrade from '../AnimalGrade/AnimalGrade';

type Props = {
  onError: (error: string) => void;
  onSuccess: (suc: string) => void;
};

export default function AddAnimalForm({ onSuccess, onError }: Props) {

  // Local animal grade state to pass to the AnimalGrade component
  const [animalGrade, setAnimalGrade] = useState<AnimalGradeType>({
    animalAge: 0,
    animalLetterRating: '',
    animalName: '',
    animalNumberRating: '',
    animalSymbolRating: '',
    animalAgeGrade: ''
  });
  
  // Save animal and if there are any errors when saving, display it.
  const [addAnimal, { error, data, loading }] = useMutation(SAVE_ANIMAL, {
    errorPolicy: 'all'
  });

  // Submit the animal with the provided data in the form
  // formData is automatically generated by the Kendo React Grid
  // TODO: On success, display a confirmation window
  const onSubmit = async (formData: {}) => {
    await addAnimal({
      variables: { animalInput: formData },
      refetchQueries: [{ query: GET_ANIMALS_DATA }]
    });
  };

  // If there is an error, display it in the browser's console.
  // TODO: Display the message as a notification
  if (error) {
    onError(error.message);
  }

  if (data) {
    onSuccess('Животното е добавено.');
  }

  // Handle changes to the inputs fields that are connected to the AnimalGrade component
  const handleChange = ({ value, target }: any) => {
    setAnimalGrade({
      ...animalGrade,
      [target.props.id]: value
    });
  };

  // Handle changes to the Мускулатура Клас (EU)
  // So that it's passed to the AnimalGrade component
  const handleLetterChange = ({ value }: any) => {
    setAnimalGrade({ ...animalGrade, animalLetterRating: value });
  };

  // Handle changes to the Мускулатура корекция (+ - =) (EU)
  // So that it's passed to the AnimalGrade component
  const handleSymbolChange = ({ value }: any) => {
    setAnimalGrade({ ...animalGrade, animalSymbolRating: value });
  };

  // Handle changes to the Мастен клас (1-5) (EU)
  // So that it's passed to the AnimalGrade component
  const handleNumberChange = ({ value }: any) => {
    setAnimalGrade({ ...animalGrade, animalNumberRating: value });
  };

  // Handle changes to the 
  // so that it is passed to the AnimalGrade component
  const handleAgeChange = ({value}: any) => {
    setAnimalGrade({...animalGrade, animalAgeGrade: value});
  }

  return (
    <>
      {loading ? (
        <Loader size='large' type='pulsing' className='k-centered' />
      ) : (
        <Container fluid>
          <Row>
            <Col lg='9'>
              <Form
                onSubmit={onSubmit}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement>
                    <Row>
                      <Col>
                        <Field
                          style={{ width: '30%' }}
                          id='inspectorName'
                          name='inspectorName'
                          label='Име на инспектор'
                          component={FormInput}
                          validator={requiredValidator}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          id='animalName'
                          name='animalName'
                          label='Ушна Марка'
                          component={FormInput}
                          validator={requiredValidator}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col>
                        <Field
                          id='animalAge'
                          name='animalAge'
                          label='Възраст (м.)'
                          component={FormNumericTextBox}
                          validator={requiredValidator}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col>
                        <Field
                          id='carcassID'
                          name='carcassID'
                          label='Труп номер'
                          component={FormInput}
                          validator={requiredValidator}
                        />
                      </Col>
                      <Col>
                        <Field
                          id='batchNumber'
                          name='batchNumber'
                          label='Партида номер'
                          component={FormInput}
                          validator={requiredValidator}
                        />
                      </Col>
                      <Col>
                        <Field 
                          id='animalNote'
                          name='animalNote'
                          label='Бележка'
                          optional={true}
                          component={FormTextArea}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          id='animalWeight'
                          name='animalWeight'
                          label='Живо тегло (кг.)'
                          component={FormNumericTextBox}
                          validator={requiredValidator}
                        />
                      </Col>
                      <Col>
                        <Field
                          id='carcassWeight'
                          name='carcassWeight'
                          label='Трупно тегло (кг.)'
                          component={FormNumericTextBox}
                          validator={requiredValidator}
                        />
                      </Col>
                      <Col>
                        <Field
                          id='animalBreed'
                          name='animalBreed'
                          label='Порода'
                          component={FormAutoComplete}
                          data={CattleBreeds}
                          validator={requiredValidator}
                        />
                      </Col>
                    </Row>
                    <Field
                      defaultValue={undefined}
                      id='animalLetterRating'
                      name='animalLetterRating'
                      label='Мускулатура Клас (EU)'
                      data={ratingLetters}
                      component={FormRadioGroup}
                      validator={requiredValidator}
                      onChange={handleLetterChange}
                    />
                    <Field
                      defaultValue={undefined}
                      id='animalSymbolRating'
                      name='animalSymbolRating'
                      label='Мускулатура корекция (+ - =) (EU)'
                      data={ratingSymbol}
                      component={FormRadioGroup}
                      validator={requiredValidator}
                      onChange={handleSymbolChange}
                    />
                    <Field
                      defaultValue={undefined}
                      id='animalNumberRating'
                      name='animalNumberRating'
                      label='Мастен клас (1-5) (EU)'
                      data={ratingNumber}
                      component={FormRadioGroup}
                      validator={requiredValidator}
                      onChange={handleNumberChange}
                    />
                    <Field
                      defaultValue={undefined}
                      id='animalAgeClass'
                      name='animalAgeClass'
                      label='Клас възраст (EU)'
                      data={ageClassEU}
                      component={FormRadioGroup}
                      validator={requiredValidator}
                      onChange={handleAgeChange}
                    />
                    <Field
                      defaultValue={undefined}
                      id='animalMarblingRating'
                      name='animalMarblingRating'
                      label='Мараморираност (US)'
                      data={marblingRating}
                      component={FormRadioGroup}
                      validator={requiredValidator}
                    />
                    <div className='k-form-buttons'>
                      <Button
                        primary={true}
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        Запиши
                      </Button>
                      <Button onClick={formRenderProps.onFormReset}>
                        Изчисти
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            </Col>
            <Col lg='3'>
              <AnimalGrade grade={animalGrade} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
