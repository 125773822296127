import React, { useState } from 'react';
import './App.css';
import logo from './BLB-logo-bg 01 Artboard 6.svg';
import { ApolloProvider } from '@apollo/client';
import '@progress/kendo-theme-material/dist/all.css';
import AnimalGrid from '../AnimalGrid/AnimalGrid';
import { client } from './Client';
import AddAnimalForm from '../AddAnimalForm/AddAnimalForm';
import {
  NotificationGroup,
  Notification
} from '@progress/kendo-react-notification';
import { Fade } from 'react-bootstrap';

function App() {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  console.log(success);
  return (
    <div className='App'>
      <div id='app-title' style={{display: 'flex'}}>
        <img src={logo} style={{width: '118px'}} alt='BLB Trace Animal Grading' />
        <h1 style={{fontSize: '58px', color: 'rgb(255, 20, 90)', alignSelf: 'center' }}>
          SEUROP Manager
        </h1>
      </div>
      <ApolloProvider client={client}>
        <AddAnimalForm
          onError={(err: string) => setError(err)}
          onSuccess={(suc: string) => setSuccess(suc)}
        />
        <AnimalGrid />
      </ApolloProvider>
      {error && (
        <NotificationGroup
          style={{
            right: 0,
            bottom: 0,
            alignItems: 'flex-end',
            flexWrap: 'wrap-reverse'
          }}
        >
          <Fade>
            <Notification
              type={{ style: 'error', icon: true }}
              closable={false}
            >
              {error}.
            </Notification>
          </Fade>
        </NotificationGroup>
      )}
      {success && (
        <NotificationGroup
          style={{
            right: 0,
            bottom: 0,
            alignItems: 'flex-end',
            flexWrap: 'wrap-reverse'
          }}
        >
          <Fade>
            <Notification
              type={{ style: 'success', icon: true }}
              closable={false}
            >
              Животното е добавено.
            </Notification>
          </Fade>
        </NotificationGroup>
      )}
    </div>
  );
}

export default App;
