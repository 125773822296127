import { gql } from '@apollo/client';


export const GET_ANIMALS_DATA = gql`
  query {
    animals {
      id
      animalName
      carcassID
      animalWeight
      carcassWeight
      weightDifference
      animalAgeClass
      animalSEUROPRating
      animalMarblingRating
      inspectionDate
      animalBreed
      animalNote
    }
  }
`;
