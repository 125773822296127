import { getter } from '@progress/kendo-react-common';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

export const emailValidator = (value: string) =>
  !value
    ? 'Email field is required.'
    : emailRegex.test(value)
    ? ''
    : 'Email is not in a valid format.';
export const nameValidator = (value: string | any[]) =>
  !value
    ? 'Full Name is required'
    : value.length < 7
    ? 'Full Name should be at least 7 characters long.'
    : '';
export const requiredValidator = (value: any) =>
  value ? '' : 'Това поле е задължително.';
export const passwordValidator = (value: string | any[]) =>
  value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = (value: any) =>
  value ? '' : 'Address is required.';

const userNameGetter = getter('username');
const emailGetter = getter('email');

export const formValidator = (values: any) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    username: !userName ? 'User Name is required.' : '',
    email:
      emailValue && emailRegex.test(emailValue)
        ? ''
        : 'Email is required and should be in a valid format.'
  };
};
