import React from 'react';
import { AnimalGradeType } from '../../types';

const empty: string = '-';
type AnimalGradeProps = {
  grade: AnimalGradeType;
};
export default function AnimalGrade({ grade }: AnimalGradeProps) {
  const {
    animalAge,
    animalSymbolRating,
    animalNumberRating,
    animalName,
    animalLetterRating,
    animalAgeGrade
  } = grade;
  return (
    <div style={{ borderLeft: 'solid brown 2px' }}>
      <div id='grade' style={{ paddingLeft: '25px', textAlign: 'center' }}>
        <h1>Оценка SEUROP</h1>
        <p>
          | {animalLetterRating || empty}
          {animalSymbolRating || empty}
          {animalNumberRating || empty} | {animalAgeGrade || empty} |
        </p>
        <p>
          | {animalName || empty} | {animalAge} |
        </p>
      </div>
    </div>
  );
}
