import React, { useRef } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { State, process } from '@progress/kendo-data-query';
import { Loader } from '@progress/kendo-react-indicators';
import { useMutation, useQuery } from '@apollo/client';
import { AnimalsResponseType } from '../../types';
import { GET_ANIMALS_DATA } from '../../utils/queries';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { DELETE_ANIMALS } from '../../utils/mutations';

const appDateFormat = 'DD/MM/YYYY';

const initialDataState: State = {
  sort: [{ field: 'code', dir: 'asc' }],
  take: 10,
  skip: 0,
  filter: {
    logic: 'and',
    filters: [{ field: 'contragentName', operator: 'contains', value: '' }]
  }
};

export default function AnimalGrid() {
  // Export functions
  const exclExport = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (exclExport.current !== null) {
      exclExport.current.save();
    }
  };

  const { data, loading } = useQuery<AnimalsResponseType>(GET_ANIMALS_DATA);

  // Grid state and functions
  const [dataState, setDataState] = React.useState<State>(initialDataState);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };
  // Filter logic
  const onSearch = async ({ target }: any) => {
    setDataState({
      ...dataState,
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'animalName',
            operator: 'contains',
            value: target.value
          }
        ]
      }
    });
  };

  const DateCell = ({ dataItem }: GridCellProps) => {
    const date = moment(dataItem?.inspectionDate).format(appDateFormat);
    return <td>{date}</td>;
  };

  // Delete all animals from the database
  const [deleteAnimals, { error }] = useMutation(DELETE_ANIMALS, {
    errorPolicy: 'all'
  });

  // run the mutation no button click
  const removeAnimals = async () => {
    await deleteAnimals({
      // after the mutation is done, refetch the initial query, in order to refresh the grid
      refetchQueries: [{ query: GET_ANIMALS_DATA }]
    });
  };

  if (error) {
    console.log('error: ', error.message);
  }
  return loading ? (
    <Loader size='large' type='pulsing' className='k-centered' />
  ) : (
    <ExcelExport data={data?.animals} ref={exclExport}>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data?.animals || [], dataState)}
      >
        <GridToolbar>
          <input placeholder='Търси...' onChange={onSearch} />
          <Button
            title='Export Excel'
            className='k-button k-primary'
            onClick={excelExport}
          >
            Export to Excel
          </Button>
          <Button
            title='Export Excel'
            className='k-button k-primary'
            onClick={removeAnimals}
          >
            Изтрии
          </Button>
        </GridToolbar>
        <Column
          field='inspectionDate'
          cell={DateCell}
          title='Дата на инспекцията'
          format='{0:d}'
        />
        <Column field='animalName' title='ID на животното' />
        <Column field='carcassID' title='ID труп' />
        <Column field='animalWeight' title='Живо тегло кг.' />
        <Column field='carcassWeight' title='Трупно тегло кг.' />
        <Column field='weightDifference' title='Ранд %' format='{0:p}' />
        <Column field='animalSEUROPRating' title='Оценка SEUROP' />
        <Column field='animalAgeClass' title='Възраст Клас EU' />
        <Column field='animalMarblingRating' title='Марморираност' />
        <Column field='animalBreed' title='Порода' />
        <Column field='animalNote' title='Бележка' />
      </Grid>
    </ExcelExport>
  );
}
