import { gql } from '@apollo/client';

export const SAVE_ANIMAL = gql`
  mutation SaveAnimal($animalInput: AnimalInput!) {
    addAnimal(animalInput: $animalInput) {
      id
      animalName
      carcassID
      animalWeight
      carcassWeight
      weightDifference
      animalAgeClass
      animalSEUROPRating
      animalBreed
    }
  }
`;

export const DELETE_ANIMALS = gql`
  mutation DeleteAnimals {
    deleteAnimals
  }
`;