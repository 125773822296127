type ratingType = {
  label: string;
  value: string;
};
export const ratingLetters: ratingType[] = [
  { label: 'S', value: 'S' },
  { label: 'E', value: 'E' },
  { label: 'U', value: 'U' },
  { label: 'R', value: 'R' },
  { label: 'O', value: 'O' },
  { label: 'P', value: 'P' }
];

export const ratingSymbol: ratingType[] = [
  { label: '-', value: '-' },
  { label: '+', value: '+' },
  { label: '=', value: '=' }
];

export const ratingNumber: ratingType[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' }
];

export const ageClassEU: ratingType[] = [
    {label: 'Z', value: 'Z'},
    {label: 'A', value: 'A'},
    {label: 'B', value: 'B'},
    {label: 'C', value: 'C'},
    {label: 'D', value: 'D'},
    {label: 'E', value: 'E'},
]

export const marblingRating: ratingType[] = [
{label: 'Неизвестен', value: 'Неизвестен'},
{label: 'Prime', value: 'Prime'},
{label: 'Choice', value: 'Choice'},
{label: 'Select', value: 'Select'},
{label: 'Друго', value: 'Друго'}
]