const CattleBreeds = [
  'Абердин Ангус',
  'Обрак',
  'Айршир',
  'Белгийско Синьо',
  'Блонд Акитен',
  'Брафорд',
  'Брахман',
  'Брангус',
  'Кафяво говедо',
  'Кианина',
  'Шароле',
  'Гаскон',
  'Галоуей',
  'Херефорд',
  'Хайленд',
  'Холщайн',
  'Червен Холщайн',
  'Джерсей',
  'Лимузин',
  'Монбелиард',
  'Маркиджана',
  'Пратенез',
  'Пиемонтез',
  'Червен Ангус',
  'Романьола',
  'Салер',
  'Симентал',
  'Кръстоска',
  'Неизвестна',
  'Вагу',
  'Искърско говедо'
];

export default CattleBreeds;